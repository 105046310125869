.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
}
.nav_list {
  display: flex;
  list-style: none;
  gap: 10%;
}
.nav-item {
  text-decoration: none;
  color: #111827;
  font-weight: 600;
  font-size: 10pt;
  transition: 0.3s;
}
.nav-item:hover {
  color: #ED3A3A;
  font-weight: 800;
}
.login-btn {
  transition: 0.3s ease;
}
.login-btn:hover {
  background-color: #ED3A3A;
  color: #FFF;
}
.home-content {
  display: flex;
}
.home-subtitle {
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 12pt;
}
.home-content-left {
  width: 55%;
  max-width: 55%;
}
.home-father {
  align-items: center;
}
.home-contact-us {
  display: flex;
  align-items: center;
}


/* mobile */

.home-subtitle-mobile {
  margin-top: 8%;
  margin-bottom: 8%;
  font-size: 11pt;
  text-align: center;
}

.navbar-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 6%;
  margin-bottom: 6%;
}

.home-contact-us-mobile {
  display: flex;
  justify-content: center;
  margin: auto;
}