.clients {
  padding-top: 1%;
  padding-bottom: 1%;
  border-top: 1px solid #E5E7EB;
  border-bottom: 1px solid #E5E7EB;
}

.clients-list {
  justify-content: center;
  align-items: center;
  display: flex;
  list-style: none;
  gap: 6%;
}

/* mobile */

.clients-list-mobile {
  justify-content: center;
  align-items: center;
  display: flex;
  list-style: none;
  gap: 6%;
  margin-left: 3%;
  margin-right: 3%;
}
