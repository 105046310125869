.title-integrations {
    font-size: 24pt;
    font-weight: 700;
}
.subtitle-integrations {
    font-size: 11pt;
    margin-bottom: 6%;
}
.integration-content {
    display: flex;
    align-items: center;
    margin-top: 8%;
    margin-bottom: 8%;
    border-top: 2px solid #f6f6f6;
}
.integration-content-left {
    width: 40%;
    max-width: 40%;
    margin-right: 10%;
    position: relative;
    top: 25%;
}
.integration-list {
    display: flex;
}

/* mobile */

.integration-content-mobile {
    align-items: center;
    margin-top: 15%;
    margin-bottom: 8%;
    border-top: 2px solid #f6f6f6;
}
.title-integrations-mobile {
    font-weight: bold;
    margin-bottom: 1%;
    font-size: 22pt;
    text-align: center;
    margin-top: 2%;
}
.subtitle-integrations-mobile {
    margin-bottom: 8%;
    font-size: 10pt;
    text-align: center;
}
