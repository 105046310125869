.padding-testimonials {
    padding-bottom: 6%;
    padding-top: 6%;
}
.title-testimonials {
    font-size: 24pt;
    font-weight: 700;
    color: #fff;
}
.subtitle-testimonials {
    font-size: 10pt;
    margin-bottom: 6%;
    color: #b4b4b4;
}
.image-testimonial {
    margin-bottom: 4%;
}
.card-testimonial {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 32px;
    margin-left: 3%;
    margin-right: 3%;
}
.aria-testimonial {
    display: flex;
}
.aria-testimonial-left {
    color: #ED3A3A;
    font-size: 36pt;
    font-family: 'Times New Roman', serif;
    margin-right: 2%;
}
.aria-testimonial-right {
}
.quote-testimonial {
    font-size: 11pt;
    color: #111827;
    margin-bottom: 4%;
}
.name-testimonial {
    font-weight: bold;
    font-size: 12pt;
    color: #111827;
}
.position-testimonial {
    font-size: 10pt;
    color: #6B7280;
}