.footer-content {
    padding-top: 6%;
    padding-bottom: 2%;
    display: flex;
    justify-content: space-between;
}
.footer-content-left {
    width: 50%;
    max-width: 50%;
    margin-right: 2%;
    top: 25%;
}
.footer-signature {
    margin-top: 5%;
    color: #7a7a7a;
    font-size: 8pt;
}
.navbar-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8%;
}
.nav-list-footer {
  display: flex;
  list-style: none;
  gap: 15%;
}
.nav-item-footer {
  text-decoration: none;
  color: #bebebe;
  font-size: 9pt;
  transition: 0.3s;
  width: 100%;
}
.nav-item-footer:hover {
  color: #ED3A3A;
  font-weight: bold;
}
.store-button-footer {
  width: 135px;
  height: 40px;
  margin-bottom: 15%;
}

/* mobile */

.footer-content-mobile {
  padding-top: 6%;
  padding-bottom: 20%;
  display: flex;
  justify-content: space-between;
}