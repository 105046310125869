.management-title {
    font-size: 24pt;
    font-weight: bold;
    color: #FFF;
}
.subtitle {
    font-size: 14pt;
    margin-bottom: 6%;
}
.management-content {
    display: flex;
    align-items: center;
    margin-top: 4%;
}
.management-content-left {
    margin-right: 8%;
}
.management-content-right {
    position: relative;
    top: 25%;
}
.btn-seccondary-wht {
    border-radius: 8px;
    border: 1px solid #FFF;
    background-color: transparent;
    color: #FFF;
    padding: 16px 24px;
    font-size: 10pt;
    font-weight: 600;
    margin-top: 6%;
    float: left;
    transition: 0.3s;
}
.btn-seccondary-wht-mobile {
    border-radius: 8px;
    border: 1px solid #FFF;
    background-color: #FFF;
    color: var(--primary-color);
    padding: 16px 24px;
    font-size: 10pt;
    font-weight: 600;
    float: left;
    transition: 0.3s;
}
.btn-seccondary-wht:hover {
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.1);
    background-color: #FFF;
    color: #ED3A3A;
}

/* mobile */

.management-content-mobile {
    align-items: center;
    margin-top: 4%;
}

.title-mobile {
    font-size: 22pt;
    font-weight: bold;
    color: #FFF;
    text-align: center;
    padding-top: 10%;
    margin-bottom: 4%;
}
.subtitle-management-mobile {
    margin-bottom: 8%;
    font-size: 10pt;
    text-align: center;
}