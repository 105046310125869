.padding-features {
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: center;
}
.style-h1 {
    font-size: 36pt;
    font-weight: bold;
    margin-bottom: 6%;
}
.features-list {
    display: flex;
    gap: 4%;
    margin-bottom: 6%;
    vertical-align: top;
    justify-content: space-around;
}
.icon-style {
    margin-bottom: 6%;
}
.feature-header {
    font-size: 20pt;
    font-weight: 700;
}

/* mobile */

.feature-h1-mobile {
    font-weight: bold;
    margin-bottom: 6%;
    font-size: 22pt;
}

.feature-header-mobile {
    font-size: 14pt;
    font-weight: 700;
}

.feature-description-mobile {
    font-size: 8pt;
}