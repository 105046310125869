.fix-header-open {
    position: fixed;
    z-index: 10;
    width: calc(100%-225px);
}

.fix-header-closed {
    position: fixed;
    z-index: 10;
    width: 100%;
}