.padding-conquests {
    padding-top: 4%;
    padding-bottom: 4%;
}
.title-conquests {
    font-size: 24pt;
    font-weight: 700;
}
.subtitle-conquests {
    font-size: 11pt;
    margin-bottom: 6%;
}
.conquest-content {
    display: flex;
    align-items: center;
}
.conquest-content-left {
    width: 40%;
    max-width: 40%;
    margin-right: 5%;
    position: relative;
    top: 25%;
}
.conquest-list {
    display: flex;
    gap: 4%;
    padding-bottom: 3%;
}

/* mobile */

.conquest-content-mobile {
    text-align: center;
    margin-top: 15%;
}
.subtitle-conquests-mobile {
    margin-bottom: 10%;
    font-size: 10pt;
    text-align: center;
}
.title-conquests-mobile {
    font-weight: bold;
    margin-bottom: 1%;
    font-size: 22pt;
}