@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
  --background: #ffffff;
  --foreground: #171717;
  --primary-color: #ED3A3A;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

.institutional-body {
  color: var(--foreground);
  background: var(--background);
  font-family: "Inter", serif;
}

.title {
  font-size: 48pt;
  font-weight: bold;
} 

.btn-primary-mobile {
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: var(--background);
  padding: 12px 24px;
  font-size: 10pt;
  font-weight: 600;
  transition: 0.3s;
}

.btn-primary {
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: var(--background);
  padding: 12px 24px;
  font-size: 10pt;
  font-weight: 600;
  transition: 0.3s;
}

.btn-primary:hover {
  box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.1);
}


.btn-seccondary {
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  padding: 12px 24px;
  font-size: 10pt;
  font-weight: 600;
}

.btn-terciary {
  background-color: transparent;
  color: var(--primary-color);
  padding: 12px 24px;
  font-size: 10pt;
  font-weight: 600;
  color: var(--primary-color);
  margin-left: 5%;
  display: flex;
  transition: 0.5s;
  width: 100%;
}

.btn-terciary:hover {
  text-decoration: underline;
}

.content-4-3 {
  padding-left: 7.77%;
  padding-right: 7.77%;
}

.content-16-9 {
  padding-left: 7.77%;
  padding-right: 7.77%;
}

.content-21-9 {
  padding-left: 25%;
  padding-right: 25%;
}

.content-mobile {
  padding-left: 5%;
  padding-right: 5%;
}

.margin-l-full {
  margin-left: 3%;
}
.margin-r-full {
  margin-right: 3%;
}
.margin-t-full {
  margin-top: 3%;
}
.margin-b-full {
  margin-bottom: 3%;
}
.margin-l-half {
  margin-left: 1.5%;
}
.margin-r-half {
  margin-right: 1.5%;
}
.margin-t-half {
  margin-top: 1.5%;
}
.margin-b-half {
  margin-bottom: 1.5%;
}